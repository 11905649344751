import { createSlice } from '@reduxjs/toolkit'

const initialDuplicate = () => {
    return {
        isDraft: false,
        id: null,
    }
}

export const duplicateSlice = createSlice({
    name: 'duplicate',
    initialState: {
        duplicateState: initialDuplicate(),
    },
    reducers: {
        setDuplicate: (state, action) => {
            state.duplicateState = action.payload
        },
        resetDuplicate: (state) => {
            state.duplicateState = initialDuplicate()
        },
    },
})

export const { setDuplicate, resetDuplicate } = duplicateSlice.actions

export default duplicateSlice.reducer
