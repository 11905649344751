import axios, { AxiosResponse } from 'axios'
import { AuthResponse } from 'models/response/AuthResponse'
import { urlAPI } from 'api/config'
export const $api = axios.create({
    withCredentials: true,
    baseURL: urlAPI,
})
export default class AuthService {
    static async login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/authorize', { login, password })
    }
}
