// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** ThemeConfig Import
import themeConfig from '../configs/themeConfig'

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        navbarType: themeConfig.layout.navbar.type,
        menuHidden: themeConfig.layout.menu.isHidden,
        contentWidth: themeConfig.layout.contentWidth,
        menuCollapsed: themeConfig.layout.menu.isCollapsed,
        routerTransition: themeConfig.layout.routerTransition,
        navbarColor: themeConfig.layout.navbar.backgroundColor,
        currentParty: '',
    },
    reducers: {
        handleNavbarType: (state, action) => {
            state.navbarType = action.payload
        },
        handleMenuHidden: (state, action) => {
            state.menuHidden = action.payload
        },
        handleNavbarColor: (state, action) => {
            state.navbarColor = action.payload
        },
        handleContentWidth: (state, action) => {
            state.contentWidth = action.payload
        },
        handleMenuCollapsed: (state, action) => {
            state.menuCollapsed = action.payload
            window.localStorage.setItem('menuCollapsed', JSON.stringify(action.payload))
        },
        handleRouterTransition: (state, action) => {
            state.routerTransition = action.payload
        },
        setCurrentParty: (state, action) => {
            state.currentParty = action.payload
        },
    },
})

export const {
    handleMenuHidden,
    handleNavbarType,
    handleNavbarColor,
    handleContentWidth,
    handleMenuCollapsed,
    handleRouterTransition,
    setCurrentParty,
} = layoutSlice.actions

export default layoutSlice.reducer
