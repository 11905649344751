// ** Reducers Imports
import layout from 'redux/layout'
import auth from 'redux/authentication'
import duplicate from 'redux/duplicate'

const rootReducer = {
    auth,
    layout,
    duplicate,
}

export default rootReducer
