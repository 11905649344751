import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IUser } from 'models/IUser'
import AuthService from 'services/AuthService'
import axios, { AxiosError } from 'axios'
import { urlAPI } from 'api/config'
import { AuthResponse } from 'models/response/AuthResponse'
import { IS_MNS } from 'constants/admin/admin'

type Error = {
    errorMessage: string
}
export const login = createAsyncThunk(
    'authentication/login',
    async ({ login, password }: { login: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await AuthService.login(login, password)
            return {
                data: response.data,
            }
        } catch (err) {
            const error: AxiosError<Error> = err as any
            if (!error.response) {
                throw err
            }
            return rejectWithValue(error.response.data.errorMessage)
        }
    },
)
export const checkAuth = createAsyncThunk('authentication/checkAuth', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.get<AuthResponse>(`${urlAPI}/refresh`, { withCredentials: true })
        return {
            data: response.data,
        }
    } catch (error: any) {
        return rejectWithValue(error)
    }
})

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        user: {} as IUser,
        isAuth: !!localStorage.getItem('token'),
        isLoading: false,
        authError: '' as string | unknown,
        isMNS: IS_MNS.includes(process.env.REACT_APP_BUILD ?? ''),
    },
    reducers: {
        handleLogout: (state) => {
            state.user = {} as IUser
            state.isAuth = false
            localStorage.removeItem('token')
        },
        handleLogin: (state, action) => {
            localStorage.setItem('token', action.payload.data.accessToken)
            state.isAuth = true
            state.user = action.payload.data.currentUser
        },
        resetAuthError: (state) => {
            state.authError = ''
        },
        setUserCert: (state, action) => {
            state.user.certSN = action.payload
        },
        updateUser: (state, action) => {
            state.user = { ...state.user, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            localStorage.setItem('token', action.payload.data.accessToken)
            state.isAuth = true
            state.user = action.payload.data.currentUser
        })
        builder.addCase(login.rejected, (state, action) => {
            state.authError = action.payload
        })
        builder.addCase(checkAuth.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(checkAuth.fulfilled, (state, action) => {
            localStorage.setItem('token', action.payload.data.accessToken)
            state.isLoading = false
            state.isAuth = true
            state.user = action.payload.data.currentUser
        })
        builder.addCase(checkAuth.rejected, (state) => {
            state.isLoading = false
            state.isAuth = false
            state.user = {} as IUser
            localStorage.removeItem('token')
        })
    },
})

export const { handleLogout, resetAuthError, handleLogin, setUserCert, updateUser } = authSlice.actions

export default authSlice.reducer
