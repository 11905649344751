declare var process: {
    env: {
        REACT_APP_API_DOMAIN_EDI: string
        REACT_APP_BUILD: 'TTN_ONLY' | 'ADMIN' | 'TTN_ONLY_DDC_PUB' | 'ADMIN_DDC_PUB' | 'MNS' | 'MNS_DDC_PUB'
        REACT_APP_API_DOMAIN_EDI_ADMIN: string
        REACT_APP_API_DOMAIN_DDC_PUB: string
        REACT_APP_API_DOMAIN_ADMIN_DDC_PUB: string
        REACT_APP_API_DOMAIN_MNS: string
        REACT_APP_API_DOMAIN_MNS_DDC_PUB: string
    }
}
export const baseURL = process.env.REACT_APP_BUILD
    ? {
          MNS: process.env.REACT_APP_API_DOMAIN_MNS,
          TTN_ONLY: process.env.REACT_APP_API_DOMAIN_EDI,
          ADMIN: process.env.REACT_APP_API_DOMAIN_EDI_ADMIN,
          TTN_ONLY_DDC_PUB: process.env.REACT_APP_API_DOMAIN_DDC_PUB,
          ADMIN_DDC_PUB: process.env.REACT_APP_API_DOMAIN_ADMIN_DDC_PUB,
          MNS_DDC_PUB: process.env.REACT_APP_API_DOMAIN_MNS_DDC_PUB,
      }[process.env.REACT_APP_BUILD]
    : process.env.REACT_APP_API_DOMAIN_EDI

const url = process.env.REACT_APP_BUILD
    ? {
          MNS: '/mns',
          TTN_ONLY: '/webapi',
          ADMIN: '/webapiadmin',
          TTN_ONLY_DDC_PUB: '/webapi',
          ADMIN_DDC_PUB: '/webapiadmin',
          MNS_DDC_PUB: '/mns',
      }[process.env.REACT_APP_BUILD]
    : '/webapi'

export const urlAPI = baseURL + url
