export const PAGE_SIZE = 10

export const STATUSES = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED',
    CHECKING: 'CHECKING',
    REG_REJECTED: 'REG_REJECTED',
}
export const STATUSES_LABEL = {
    ACTIVE: 'Активный',
    BLOCKED: 'Заблокированный',
    CHECKING: 'Проверка',
    REG_REJECTED: 'Отклонён',
}

export const TYPE_STORAGE = {
    STORAGE: 'Склад',
    PUBLIC_GLN: 'Общественный GLN',
}
export const IS_MNS = ['MNS', 'MNS_DDC_PUB']
