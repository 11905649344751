import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'

const locizeOptions = {
    projectId: '237eb656-9e62-423f-b1b3-c24ae04dd766',
    apiKey: 'd38d6e76-7ce3-4d6c-9edf-50b274109e63',
    referenceLng: 'ru-RU',
}
const settings = {
    fallbackLng: 'ru-RU',
    backend: locizeOptions,
    saveMissing: true,
}
if (process.env.REACT_APP_ENABLE_LANGUAGE === 'false') {
    settings.lng = 'ru-RU'
}
i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init(settings)
export default i18next
