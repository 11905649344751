// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from 'redux/store'

import './i18n'

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify'

// ** Spinner (Splash Screen)
import Spinner from 'components/spinner/Fallback-spinner'

// ** Ripple Button
import 'components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@core/scss/react/libs/toastify/toastify.scss'

// ** Core styles
import './assets/fonts/feather/iconfont.css'
import '@core/scss/core.scss'
// ** Service Worker
import * as serviceWorker from './serviceWorker'
import 'flatpickr/dist/themes/light.css'
import './index.scss'
import './@core/scss/base/antd-config.scss'
import { ConfigProvider } from 'antd'
import Ru from 'antd/locale/ru_RU'
// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
    <ConfigProvider
        button={{ className: 'buttonConfigAntd' }}
        locale={Ru}
        spin={{ className: 'spinConfigAntd' }}
        table={{ className: 'tableConfigAntd' }}
        theme={{
            token: {
                colorPrimary: '#1C3BBE',
            },
        }}>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <LazyApp />
                <ToastContainer newestOnTop />
            </Suspense>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
